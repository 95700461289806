<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Selecione os campos abaixo:</v-card-title>
        <v-card-text>
          <v-col class="d-flex flex-column" cols="12" sm="12">
            <v-card-title class="text-subtitle-1 pb-1">Mês:</v-card-title>
            <v-select
              :items="$constants.meses"
              item-text="descricao"
              item-value="id"
              v-model="selectMonth"
              return-object
              label="Selecione um mês"
              solo
              class="mb-1"
            ></v-select>
            <v-divider></v-divider>
            <v-card-title class="text-subtitle-1 pb-1">Franquia:</v-card-title>
            <v-select
              :items="franquiasList.filter((item) => !item.deleted_at)"
              :item-text="(item) => item.descricao"
              v-model="franquiaSelecionada"
              return-object
              label="Selecione uma franquia"
              solo
            ></v-select>
          </v-col>
        </v-card-text>
        <v-card-text v-if="errorMessage">
          <v-alert type="error">{{ errorMessage }}</v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" @click="$emit('dialogChange', false)">Fechar</v-btn>
          <v-btn color="primary" text @click="relatorioFinanceiroFranquia">Gerar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Franquias", ["franquiasList"]),
  },
  data() {
    return {
      selectMonth: null,
      selectTurma: null,
      franquiaSelecionada: null,
      turmasList: [],
      errorMessage: "", // Adicione a propriedade errorMessage
    };
  },
  methods: {
    ...mapActions("Franquias", ["loadFranquias"]),
    emitChangeAction() {
      if (this.validForm) {
        this.$emit("changeAction", this.selectMonth, this.franquiaSelecionada);
      }
    },
    async loadData() {
      try {
        this.turmasList = await this.$services.turmasService.syncAll();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async relatorioFinanceiroFranquia() {
      this.errorMessage = ""; // Limpe a mensagem de erro ao iniciar
      try {
        if (!this.selectMonth || !this.franquiaSelecionada) {
          this.errorMessage = "Selecione um mês e uma franquia";
          return;
        }
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatorioFinanceiroFranquia(
          this.franquiaSelecionada.id,
          this.selectMonth.id
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped></style>
