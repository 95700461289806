<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h5 mb-2;">Relatório Financeiro</h2>
        <e-label>Pesquise uma unidade escolar aqui</e-label>
        <v-autocomplete
          v-model="escola"
          :items="this.franquiasList"
          item-text="descricao"
          item-value="id"
          dense
          return-object
          filled
          label="Pesquise uma escola"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!--  -->
    <v-row>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="openModalMonth(escola.id)">
          <template v-slot:progress>
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title style="color: white">
            <v-icon large left style="color: white">fa fa-chalkboard-teacher</v-icon>
            <span class="title font-weight-light">Financeiro por mês - Turma</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold" style="color: white"> Turmas </v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="openModalMonthFranquia(escola.id)">
          <template v-slot:progress>
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title style="color: white">
            <v-icon large left style="color: white">fa fa-chalkboard-teacher</v-icon>
            <span class="title font-weight-light">Financeiro por mês - Escola</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold" style="color: white">
            Escola Atual
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <e-modal-select-month-financeiro
      :dialog="dialog"
      @dialogChange="dialog = $event"
    ></e-modal-select-month-financeiro>

    <e-modal-select-month-franquia-financeiro
      :dialog="dialogFranquia"
      @dialogChange="dialogFranquia = $event"
    ></e-modal-select-month-franquia-financeiro>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Relatorios",
  data() {
    return {
      escola: {
        descricao: null,
        id: null,
      },
      dialog: false,
      dialogFranquia: false,
      gestaoDeAulaId: 0,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquiasList", "carregandoFranquias"]),
  },
  methods: {
    async listarTodasTurmas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.listarTodasTurmas();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    openModalMonth(id) {
      this.gestaoDeAulaId = id;
      this.dialog = true;
    },

    openModalMonthFranquia(id) {
      this.escola.id = id;
      this.dialogFranquia = true;
    },
  },
  mounted() {
    this.$loaderService.open("Carregando dados...");
    this.escola = this.user.franquia;
    this.$loaderService.close();
  },
};
</script>
